<template>
  <div>
    <h3 class="ml-1" v-if="showText">{{$t('settings.darkmode')}}</h3>
    <!-- <DarkModeSwitch v-if="show" class="mb-2 mt-2" :initialState="isDarkModeEnabled" @switched="onSwitched"></DarkModeSwitch>
    <DarkModeSwitch v-else class="mb-2 mt-2 disabled" style="pointer-events: none;" :initialState="isDarkModeEnabled"></DarkModeSwitch> -->
    <div v-if="show" class="toggle-pill mt-2">
      <input type="checkbox" id="darkmode" name="darkmode" @click.stop="onSwitched" v-model="isDarkModeEnabled"/>
      <label for="darkmode"></label>
    </div>
    <div v-else class="toggle-pill mt-2" style="pointer-events: none; opacity: 60%;">
      <input type="checkbox" id="darkmode" name="darkmode" @click.stop="onSwitched" v-model="isDarkModeEnabled"/>
      <label for="darkmode"></label>
    </div>
  </div>
</template>

<script>
// import DarkModeSwitch from 'vue-dark-mode-switch'
// import 'vue-dark-mode-switch/dist/vue-dark-mode-switch.css'
export default {
  props: ['showText'],
  // components: { DarkModeSwitch },
  data: () => ({
    isDarkModeEnabled: window.localStorage.getItem('tiimio-dark') == 'true'
  }),
  methods: {
    onSwitched() {
      const newMode = !this.$vuetify.theme.dark
      window.localStorage.setItem('tiimio-dark', newMode)
      this.$vuetify.theme.dark = newMode
    }
  },
  computed: {
    show() {
      return window.localStorage.getItem('system-pref-dark') === 'false'
    }
  }
}
</script>

<style scoped>
  .disabled {
    opacity: 50%;
  }

  /* toggle-pill-color */
.toggle-pill input[type="checkbox"] {
  display: none;
}
.toggle-pill input[type="checkbox"] + label {
  display: block;
  position: relative;
  width: 3em;
  height: 1.6em;
  margin-bottom: 20px;
  border-radius: 1em;
  background: #e84d4d;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}
.toggle-pill input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0.2em;
  top: 0.2em;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.toggle-pill input[type="checkbox"]:checked + label {
  background: #47cf73;
}
.toggle-pill input[type="checkbox"]:checked + label:before {
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  left: 1.6em;
}
/* toggle-pill-color end */
</style>