<template>
  <v-container max-width="100" style="overflow-y: scroll;" fluid :class="{'dark': $vuetify.theme.dark}">
    <v-card flat max-width="1200" class="mx-auto pb-10" style="width: 100%">
      <v-card-title>
        {{$t('settings.settings')}}
      </v-card-title>
      <v-card-text>
        <DarkMode :showText="true"></DarkMode>
        <p class="mb-2">{{ $t('settings.darkmodesystem') }}</p>
        <div class="toggle-pill-color" style="display: inline;">
          <input type="checkbox" id="darkmodeSystem" name="darkmodeSystem" @click="toggleSystemPreference" v-model="initialChecked"/>
          <label for="darkmodeSystem"></label>
        </div>
      </v-card-text>
      <v-card-text>
        <div class="mb-5">
          <h3 class="ml-1 mb-2">{{$t('settings.language')}}</h3>
          <v-select
            style="max-width: 400px;"
            solo
            :items="languages"
            :value="language"
            @change="setLanguage($event)"
          ></v-select>
        </div>
        <h3 class="ml-1 mb-2">{{$t('settings.team')}}</h3>
        <ChangeTeamButton :shortenMobile="false"></ChangeTeamButton>
        <v-spacer class="mt-10"></v-spacer>
        <v-btn 
          class="mt-4"
          @click="openPortal()" 
          v-if="isOrderer && (!isApp || isAppleTesters)"
          :loading="loading"
        >
          {{$t('settings.manage_subscription')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-5" @click="$router.push('/team/join')">
          <v-icon class="mr-2">mdi-account-group</v-icon>
          {{$t('settings.join_another_team')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-5" @click="$router.push('/team/create')">
          <v-icon>mdi-plus</v-icon>
            {{$t('settings.create_a_new_team')}}
        </v-btn>
        <v-divider class="mt-8 mb-10"></v-divider>
        <v-spacer></v-spacer>
        <v-btn @click="restore()">{{$t('settings.restore')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-5" @click="logout(); $router.push('/login')">{{$t('log_out')}}</v-btn>
      </v-card-text>
      <div style="height: 100px;"></div>
      <div>
        <h3 class="danger-zone-text">{{$t('settings.danger_zone')}}</h3>
        <div class="danger-zone">
          <v-btn
            color="error"
            v-if="isOrderer"
            @click="startDeleteTeam()"
          >
            {{$t('settings.delete_team')}}
          </v-btn>
          <v-btn 
            v-else 
            class="mt-0" 
            color="error" 
            @click="startLeaveTeam()">
              {{$t('settings.leave')}} {{ currentTeam.team_name }}
          </v-btn>
          <br>
          <v-btn
            color="error"
            class="mt-4"
            @click="startDeleteUser()"
          >
            {{ $t('delete_user') }}
          </v-btn>
        </div>
      </div>
      <v-sheet style="text-align: center;" class="mt-10">
      App version: 1.2.51
      </v-sheet>
    </v-card>
    <confirmation-dialog
      :show="showLeaveDialog"
      :text="$t('settings.leave_text')"
      :subText="$t('settings.leave_text_long', [currentTeam.team_name])"
      btnColor="red"
      :btnText="$t('leave')"
      @decline="showLeaveDialog = false"
      @accept="acceptLeaveTeam()"
    ></confirmation-dialog>
    <confirmation-dialog
      :show="showDeleteTeamDialog && isOrderer"
      :text="$t('settings.delete_team_text', [currentTeam.team_name])"
      :subText="$t('settings.delete_team_text_long', [currentTeam.team_name])"
      btnColor="red"
      :btnText="$t('delete')"
      @decline="showDeleteTeamDialog = false"
      @accept="acceptDeleteTeam()"
    ></confirmation-dialog>
    <confirmation-dialog
      :show="showDeleteUserDialog"
      :subText="$t('settings.delete_user_text')"
      btnColor="red"
      :btnText="$t('delete')"
      @decline="showDeleteUserDialog = false"
      @accept="acceptDeleteUser()"
    ></confirmation-dialog>
    <v-dialog max-width="800" v-model="deletedDialog">
      <v-card>
        <v-card-title>
          {{ $t('settings.deleted') }}
        </v-card-title>
        <v-card-text>
          {{  $t('settings.deleted_long') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deletedDialog = false">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DarkMode from '@/components/settings/DarkMode'
import ChangeTeamButton from '@/components/team/ChangeTeamButton'
import { mapActions, mapGetters } from 'vuex'
import ConfirmationDialog from '../components/ConfirmationDialog.vue'

export default {
  components: { DarkMode, ChangeTeamButton, ConfirmationDialog },
  data: () => ({
    languages: [
      {
        value: 'fi',
        text: 'Suomi'
      },
      {
        value: 'en',
        text: 'English'
      }
    ],
    showLeaveDialog: false,
    showDeleteUserDialog: false,
    showDeleteTeamDialog: false,
    loading: false,
    deletedDialog: false,
    isDarkModeSystemEnabled: window.localStorage.getItem('system-pref-dark') === 'true',
  }),
  methods: {
    ...mapActions('user', [
      'orderProduct',
      'changeCurrentTeamId',
      'logout'
    ]),
    ...mapActions('team', [
      'leaveTeam'
    ]),
    ...mapActions('noti', [
      'showSnackbar',
      'error'
    ]),
    ...mapActions([
      'deleteUser',
      'deleteTeam'
    ]),
    toggleSystemPreference() {
      setTimeout(() => {
        window.location.reload();
      }, 200)
    },
    startDeleteTeam() {
      this.showDeleteTeamDialog = true
    },
    startDeleteUser() {
      this.showDeleteUserDialog = true
    },
    acceptDeleteUser() {
      this.deleteUser(this.user.email)
        .then(() => {
          this.showDeleteUserDialog = false
          this.deletedDialog = true
        })
    },
    acceptDeleteTeam() {
      this.deleteTeam(this.currentTeamId)
      .then(() => {
          this.showDeleteTeamDialog = false
          this.deletedDialog = true
        })
    },
    restore() {
      const message = JSON.stringify({
        action: 'restore'
      });

      try {
        window?.ReactNativeWebView?.postMessage(message);
      } catch(e) {
        console.log(e)
      }

      try {
        document?.ReactNativeWebView?.postMessage(message);
      } catch(e) {
        console.log(e)
      }
    },
    openPortal() {
      this.loading = true
      this.orderProduct({price_id: null})
        .then(e => {
          window.location = e.data.url
        })
        .catch(err => alert(err))
        .finally(() => this.loading = false)
    },
    setLanguage(e) {
      window.localStorage.setItem('tiimi-lang', e)
      window.location.reload()
    },
    startLeaveTeam() {
      this.showLeaveDialog = true
    },
    acceptLeaveTeam() {
      this.leaveTeam()
        .then(() => {
          this.$router.push('/videos')
        })
        .catch(e => this.error(e))
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'currentTeamId',
      'isOrderer',
      'user',
      'isAppleTesters'
    ]),
    initialChecked: {
      set(value) {
        window.localStorage.setItem('system-pref-dark', value);
      },
      get() {
      return window.localStorage.getItem('system-pref-dark') === 'true';
      },
    },
    language() {
      return window.localStorage.getItem('tiimi-lang')
    },
    isApp() {
      if(window.localStorage.getItem('tiimi-application')) return window.localStorage.getItem('tiimi-application')

      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.dark {
  background-color: #121212;
}
  .danger-zone {
    border-radius: 30px;
    border: 2px solid rgb(170, 61, 61);
    padding: 50px 20px;
    margin: 0 15px;

    &-text {
      color: red;
      margin-left: 2em;
    }
  }


  

/* toggle-pill-color */
.toggle-pill-color input[type="checkbox"] {
  display: none;
}
.toggle-pill-color input[type="checkbox"] + label {
  display: block;
  position: relative;
  width: 3em;
  height: 1.6em;
  margin-bottom: 20px;
  border-radius: 1em;
  background: #e84d4d;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}
.toggle-pill-color input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0.2em;
  top: 0.2em;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.toggle-pill-color input[type="checkbox"]:checked + label {
  background: #47cf73;
}
.toggle-pill-color input[type="checkbox"]:checked + label:before {
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  left: 1.6em;
}
/* toggle-pill-color end */
</style>